import React from "react";
import Header from "./Header";
import { motion } from "framer-motion";
import { banner } from "../assets/video";
import Bannervideo from "./Bannervideo";

const Home = () => {
  return (

   
   <div>
     <Header/>
     <Bannervideo/>
   </div>

  );
};

export default Home;
